import { HttpParams } from '@angular/common/http';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { Entity } from './entity';
import { PricingFormParticulars, PricingFormParticularsRequest } from './pricing-form-particulars';
import { ServiceCostServiceRequest } from './service-calc-units';

export class BillingItem extends Entity {
  billingitemId: number;
  billingitemService: string;
  billingitemServiceId: number;
  billingitemBiller: string;
  billingitemBillerId: number;
  billingitemBillingaccount: string;
  billingitemBillingaccountId: number;
  billingitemEnds: Date;
  billingitemStatus: string;
  billingitemProviderCode: string;
  billingitemProviderName: string;
  billingitemProvider: string;
  billingitemDeliveryitemId: number;
  billingitemProductFamily: string;
  billingitemProductFamilyId: number;
  billingitemAdditionalCosts: number;
  billingitemFixedCosts: number;
  billingitemPermissioningSystem: string;
  billingitemPermissioningSystemId: number;
  billingitemServiceCode: string;
  billingitemServiceType: string;
  billingitemUnitCosts: number;
  deleted?: boolean;
}

export class BillingItemAsset extends Entity {
  billingitemassetAsset: string;
  billingitemassetAssetId: number;
  billingitemassetBillingaccount: string;
  billingitemassetBillingaccountId: number;
  billingitemassetEnds: Date;
  billingitemassetFeeditemCode: string;
  billingitemassetFeeditemId: number;
  billingitemassetFeeditemName: string;
  billingitemassetFeeditemProvider: string;
  billingitemassetId: number;
  billingitemassetService: string;
  billingitemassetServiceId: number;
  billingitemassetStatus: string;
  billingitemassetVendor: string;
  billingitemassetVendorId: number;
}

export class BillingitemParticular {
  billingitemId: number;
  billingitemDeliveryitemId: number;
  billingitemProviderName: string;
  billingitemActivationDate: Date;
  billingitemDeactivationDate: Date;
  billingitemBillingaccount: string; // array
  billingitemBillingaccountId: number; // array
  billingitemProviderCode: string; // not show
  billingitemProvider: string; // not show
  billingitemPrice: PricingFormParticulars;
}

export class BillingitemParticularRequest {
  activationDate: Date;
  deactivationDate: Date;
  billingaccountIds: number[];
  deliveryitemId: number;
  price: PricingFormParticularsRequest;
}

export class BillingitemAddRequest {
  nodes: {
    billingitemId: number;
    billingaccountId: number;
    recordId: number;
  }[];
  comment: string;
  fileIds: number[];
}

export class BillingitemBillingAccount {
  recordId: number;
  billingitemId: number;
  billingaccountId: number;
  serviceOriginal: string;
  serviceName: string;
  billingaccount: string;
  biller: string;
  billingaccountStarts: Date;
  valid: boolean;
  validationError: string;
}

export class BillingitemCopyRequest {
  activationDate: Date;
  deactivationDate: Date;
  billingaccountIds: number[];
}

export class BillingitemReplaceRequest {
  replaceDate: Date;
  servicealiasIds: number[];
}

export class BillingitemMoveRequest {
  billingaccountId: number;
  moveDate: string;
  services: ServiceCostServiceRequest[];
}

export class BillingitemPackageRequest {
  activationDate: Date;
  deactivationDate: Date;
  packageIds: number[];
  billingitemIds: number[];
}

export function getBillingItemRequestParams(request: PaginatedRequest): HttpParams {
  return PaginatedRequest.getBaseRequestParams(request);
}

export const ENABLED_FOR_ORDER_YES = 1;

export class BillingItemParams {
  public static FILTER_BILLINGACCOUNT_IDS = 'filterBillingaccountIds';
  public static FILTER_BILLERALIAS_IDS = 'filterBilleraliasIds';
  public static FILTER_DELIVERYITEM_IDS = 'filterDeliveryitemIds';
  public static FILTER_PRODUCTFAMILY_IDS = 'filterProductfamilyIds';
  public static FILTER_SUGESTOPTDATE_IDS = 'filterSugestoptdateIds';
  public static FILTER_PRICECALC_IDS = 'filterPricecalcIds';
  public static FILTER_STATUS_IDS = 'filterStatusIds';
  public static FILTER_CATEGORY_IDS = 'filterCategoryIds';
  public static FILTER_STEERABLE_IDS = 'filterSteerableIds';
  public static FILTER_ISPACKAGE_IDS = 'filterIspackageIds';
  public static FILTER_ENABLEDFORORDER_IDS = 'filterEnabledfororderIds';
  public static FILTER_EXCLUDE_IDS = 'filterExcludeIds';
  public static FILTER_CATALOGITEM_CATEGORY_IDS = 'filterCatalogitemcategoryIds';
  public static FILTER_CATALOGITEM_TYPE_IDS = 'filterCatalogitemtypeIds';
}
