import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPath } from 'src/app/configs/api-paths';
import { ActionResponse } from 'src/app/models/action-response';
import { AssetSubscription } from 'src/app/models/asset-subscription';
import { BaseResponse } from 'src/app/models/base-response';
import {
  BillingItem,
  BillingItemParams,
  BillingitemAddRequest,
  BillingitemCopyRequest,
  BillingitemMoveRequest,
  BillingitemPackageRequest,
  BillingitemParticularRequest,
  BillingitemReplaceRequest,
} from 'src/app/models/billingitem';
import { FeeditemReplaceRequest } from 'src/app/models/feeditem';
import { EntityWizardResponse } from 'src/app/models/forms/entity-wizard';
import { PackageParams, PackageTreeNode } from 'src/app/models/package';
import { PaginatedRequest } from 'src/app/models/paginated-request';
import { PricingFormParticulars } from 'src/app/models/pricing-form-particulars';
import { Service, ServiceAlias, ServiceParams } from 'src/app/models/service';
import { BillingAccount, BillingAccountParams } from 'src/app/models/vendor-account';
import { environment } from 'src/environments/environment';
import { LogService } from '../log-service';

@Injectable({
  providedIn: 'root',
})
export class AddBillingitemsWizardDataService {
  addBillingitems(request: BillingitemParticularRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.addBillingitems.name;

    const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS_CREATION_WIZARD);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  copyBillingitems(billingitemId: number, request: BillingitemCopyRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.copyBillingitems.name;

    const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS_COPY(billingitemId));
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  replaceBillingitems(billingitemId: number, request: BillingitemReplaceRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.replaceBillingitems.name;

    const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS_REPLACE(billingitemId));
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  replaceFeeditems(feeditemId: number, request: FeeditemReplaceRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.replaceBillingitems.name;

    const path = environment.getEndpoint(ApiPath.FeedItems.FEEDITEMS_REPLACE(feeditemId));
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getFeeditemPriceDefaults(feeditemId: number, activationDate: string): Observable<BaseResponse<PricingFormParticulars>> {
    const m = this.getFeeditemPriceDefaults.name;

    const path = environment.getEndpoint(ApiPath.FeedItems.FEEDITEMS_PRICE_PARTICULARS_DEFAULT(feeditemId));
    let params = new HttpParams().set('starts', activationDate);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<PricingFormParticulars>>(path, { params });

    return retVal;
  }

  getBillingitemPriceDefaults(feeditemId: number, activationDate: string): Observable<BaseResponse<PricingFormParticulars>> {
    const m = this.getFeeditemPriceDefaults.name;

    const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS_PRICE_PARTICULARS_DEFAULT(feeditemId));
    let params = new HttpParams().set('starts', activationDate);
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<PricingFormParticulars>>(path, { params });

    return retVal;
  }

  moveBillingitems(request: BillingitemMoveRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.replaceBillingitems.name;

    const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS_MOVE);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getBillingItems(request: PaginatedRequest, feeditemId?: number): Observable<BaseResponse<BillingItem[]>> {
    const m = this.getBillingItems.name;

    const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = feeditemId != null ? params.append(BillingItemParams.FILTER_DELIVERYITEM_IDS, feeditemId) : params;
    const retVal = this.http.get<BaseResponse<BillingItem[]>>(path, {
      params,
    });

    return retVal;
  }

  getBillingItemsByBillingAccounts(billingaccountId: number): Observable<BaseResponse<BillingItem[]>> {
    const m = this.getBillingItems.name;

    const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS);
    LogService.info(this, m, LogService.GET + path, null);

    const params = new HttpParams().set('filterBillingaccountIds', billingaccountId);
    const retVal = this.http.get<BaseResponse<BillingItem[]>>(path, {
      params,
    });

    return retVal;
  }

  getBillingAccounts(request: PaginatedRequest): Observable<BaseResponse<BillingAccount[]>> {
    const m = this.getBillingAccounts.name;

    const path = environment.getEndpoint(ApiPath.BillingAccounts.BILLINGACCOUNTS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<BillingAccount[]>>(path, {
      params,
    });

    return retVal;
  }

  getBillingaccountsWithExcludes(
    feeditemId: number,
    request: PaginatedRequest,
    billingaccountId?: number
  ): Observable<BaseResponse<BillingAccount[]>> {
    const m = this.getBillingaccountsWithExcludes.name;

    const path = environment.getEndpoint(ApiPath.BillingAccounts.BILLINGACCOUNTS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = feeditemId != null ? params.append(BillingAccountParams.FILTER_EXCLUDE_DELIVERYITEM_IDS, feeditemId) : params;
    params = billingaccountId != null ? params.append(BillingAccountParams.FILTER_EXCLUDE_IDS, billingaccountId) : params;
    const retVal = this.http.get<BaseResponse<BillingAccount[]>>(path, {
      params,
    });

    return retVal;
  }

  getBillingitemPackages(billingitemId: number, request: PaginatedRequest): Observable<BaseResponse<PackageTreeNode[]>> {
    const m = this.getBillingaccountsWithExcludes.name;

    const path = environment.getEndpoint(ApiPath.Packages.PACKAGES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = params.append(PackageParams.FILTER_EXCLUDE_BILLINGITEM_IDS, billingitemId);
    const retVal = this.http.get<BaseResponse<PackageTreeNode[]>>(path, {
      params,
    });

    return retVal;
  }

  addBillingitemToPackages(billingitemId: number, request: BillingitemPackageRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.addBillingitemToPackages.name;

    const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS_PACKAGE(billingitemId));
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  addBillingitemsToPackage(packageId: number, request: BillingitemPackageRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.addBillingitemToPackages.name;

    const path = environment.getEndpoint(ApiPath.Packages.PACKAGES_BILLING_ITEMS(packageId));
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getServices(request: PaginatedRequest, feeditemId?: number): Observable<BaseResponse<Service[]>> {
    const m = this.getServices.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = feeditemId != null ? params.append(ServiceParams.FILTER_EXCLUDE_DELIVERYITEM_IDS, feeditemId) : params;
    const retVal = this.http.get<BaseResponse<Service[]>>(path, {
      params,
    });

    return retVal;
  }

  getServiceAliases(request: PaginatedRequest, feeditemId?: number): Observable<BaseResponse<ServiceAlias[]>> {
    const m = this.getServices.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICE_ALIASES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    let params = PaginatedRequest.getBaseRequestParams(request);
    params = feeditemId != null ? params.append(ServiceParams.FILTER_EXCLUDE_DELIVERYITEM_IDS, feeditemId) : params;
    const retVal = this.http.get<BaseResponse<ServiceAlias[]>>(path, {
      params,
    });

    return retVal;
  }

  addBillingitemsToBillingaccountsValidation(request: BillingitemAddRequest): Observable<BaseResponse<EntityWizardResponse>> {
    const m = this.addBillingitemsToBillingaccountsValidation.name;

    const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS_BILLINGACCOUNTS_VALIDATION);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<EntityWizardResponse>>(path, request);

    return retVal;
  }

  addBillingitemsToBillingaccounts(request: BillingitemAddRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.addBillingitemsToBillingaccounts.name;

    const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS_BILLINGACCOUNTS);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  getBillingitemAssets(request: PaginatedRequest, billingitemIds: number[], ends: string): Observable<BaseResponse<AssetSubscription[]>> {
    const m = this.getBillingItems.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_SUBSCRIPTIONS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const params = PaginatedRequest.getBaseRequestParams(request)
      .set('filterBillingitemIds', billingitemIds.join(','))
      .set('filterEnds', ends);
    const retVal = this.http.get<BaseResponse<AssetSubscription[]>>(path, {
      params,
    });

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
